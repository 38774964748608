import { Nullable } from '../utils/nullable';
import { MessageID } from '../locale/type';

// TODO: Rename it. It should be ResourceTypeEnum now.
export enum PpeTypeEnum {
  TypeIIRSurgicalMasks = 'TypeIIRSurgicalMasks',
  FFP1RespiratorMasks = 'FFP1RespiratorMasks',
  FFP2RespiratorMasks = 'FFP2RespiratorMasks',
  FFP3RespiratorMasks = 'FFP3RespiratorMasks',
  Gowns = 'Gowns',
  Aprons = 'Aprons',
  Gloves = 'Gloves',
  Scrubs = 'Scrubs',
  SafetyGlasses = 'SafetyGlasses',
  FaceVisors = 'FaceVisors',
  AlcoholHandGel = 'AlcoholHandGel',
  Other = 'Other',
  DomesticSanitarySanitaryTowels = 'DomesticSanitarySanitaryTowels',
  DomesticSanitaryNappiesSize0 = 'DomesticSanitaryNappiesSize0',
  DomesticSanitaryNappiesSize1 = 'DomesticSanitaryNappiesSize1',
  DomesticSanitaryNappiesSize2 = 'DomesticSanitaryNappiesSize2',
  DomesticSanitaryNappiesSize3 = 'DomesticSanitaryNappiesSize3',
  DomesticSanitaryNappiesSize4 = 'DomesticSanitaryNappiesSize4',
  DomesticSanitaryNappiesSize5 = 'DomesticSanitaryNappiesSize5',
  DomesticSanitaryNappiesSize6 = 'DomesticSanitaryNappiesSize6',
  DomesticSanitaryBreastPads = 'DomesticSanitaryBreastPads',
  DomesticSanitaryHairbrushes = 'DomesticSanitaryHairbrushes',
  DomesticSanitaryLiquidSoap = 'DomesticSanitaryLiquidSoap',
  DomesticSanitaryWetWipes = 'DomesticSanitaryWetWipes',
  DomesticSanitaryToothbrushes = 'DomesticSanitaryToothbrushes',
  DomesticSanitaryToothpaste = 'DomesticSanitaryToothpaste',
  DomesticSanitaryTowels = 'DomesticSanitaryTowels',
  DomesticSanitaryToiletPaper = 'DomesticSanitaryToiletPaper',
  DomesticSanitaryPocketTissues = 'DomesticSanitaryPocketTissues',
  DomesticSanitaryShavingGelRazors = 'DomesticSanitaryShavingGelRazors',
  DomesticSanitaryOther = 'DomesticSanitaryOther',
  DomesticNonPerishableFoodDrinkProteinBars = 'DomesticNonPerishableFoodDrinkProteinBars',
  DomesticNonPerishableFoodDrinkCannedFood = 'DomesticNonPerishableFoodDrinkCannedFood',
  DomesticNonPerishableFoodDrinkDryFood = 'DomesticNonPerishableFoodDrinkDryFood',
  DomesticNonPerishableFoodDrinkInstantFood = 'DomesticNonPerishableFoodDrinkInstantFood',
  DomesticNonPerishableFoodDrinkBabyFood = 'DomesticNonPerishableFoodDrinkBabyFood',
  DomesticNonPerishableFoodDrinkEnergyDrinks = 'DomesticNonPerishableFoodDrinkEnergyDrinks',
  DomesticNonPerishableOther = 'DomesticNonPerishableOther',
  DomesticOtherFoilSurvivalBlankets = 'DomesticOtherFoilSurvivalBlankets',
  DomesticOtherThermalClothingNew = 'DomesticOtherThermalClothingNew',
  DomesticOtherSleepingBags = 'DomesticOtherSleepingBags',
  DomesticOtherBedHospital = 'DomesticOtherBedHospital',
  DomesticOtherLargeOrMediumBackpacks = 'DomesticOtherLargeOrMediumBackpacks',
  DomesticOtherPowerBanksAndChargingCables = 'DomesticOtherPowerBanksAndChargingCables',
  DomesticOtherTorches = 'DomesticOtherTorches',
  DomesticOtherElectricityGenerators = 'DomesticOtherElectricityGenerators',
  DomesticOtherBootDriers = 'DomesticOtherBootDriers',
  DomesticOtherHotWaterBottles = 'DomesticOtherHotWaterBottles',
  DomesticOtherInsulatedFlasks = 'DomesticOtherInsulatedFlasks',
  DomesticOtherDisposableTableware = 'DomesticOtherDisposableTableware',
  DomesticOtherCookingStoves = 'DomesticOtherCookingStoves',
  DomesticOtherBinBags = 'DomesticOtherBinBags',
  DomesticOtherOther = 'DomesticOtherOther',
  NonDrugMedicalSuppliesMedicalEquipmentPatientMonitor = 'NonDrugMedicalSuppliesMedicalEquipmentPatientMonitor',
  NonDrugMedicalSuppliesMedicalEquipmentAnaesthesiaMachine = 'NonDrugMedicalSuppliesMedicalEquipmentAnaesthesiaMachine',
  NonDrugMedicalSuppliesMedicalEquipmentECGRecorder = 'NonDrugMedicalSuppliesMedicalEquipmentECGRecorder',
  NonDrugMedicalSuppliesMedicalEquipmentDefibrillator = 'NonDrugMedicalSuppliesMedicalEquipmentDefibrillator',
  NonDrugMedicalSuppliesMedicalEquipmentSyringePump = 'NonDrugMedicalSuppliesMedicalEquipmentSyringePump',
  NonDrugMedicalSuppliesMedicalEquipmentInfusionPump = 'NonDrugMedicalSuppliesMedicalEquipmentInfusionPump',
  NonDrugMedicalSuppliesMedicalEquipmentExaminationLightLed = 'NonDrugMedicalSuppliesMedicalEquipmentExaminationLightLed',
  NonDrugMedicalSuppliesMedicalEquipmentFootOperatedSuctionPump = 'NonDrugMedicalSuppliesMedicalEquipmentFootOperatedSuctionPump',
  NonDrugMedicalSuppliesMedicalEquipmentPatientVentilator = 'NonDrugMedicalSuppliesMedicalEquipmentPatientVentilator',
  NonDrugMedicalSuppliesMedicalEquipmentMobileUltrasoundScanner = 'NonDrugMedicalSuppliesMedicalEquipmentMobileUltrasoundScanner',
  NonDrugMedicalSuppliesMedicalEquipmentSelfInflatingBagSet = 'NonDrugMedicalSuppliesMedicalEquipmentSelfInflatingBagSet',
  NonDrugMedicalSuppliesMedicalEquipmentCapnometer = 'NonDrugMedicalSuppliesMedicalEquipmentCapnometer',
  NonDrugMedicalSuppliesMedicalEquipmentXRayUnit = 'NonDrugMedicalSuppliesMedicalEquipmentXRayUnit',
  NonDrugMedicalSuppliesMedicalEquipmentSurgicalDrill = 'NonDrugMedicalSuppliesMedicalEquipmentSurgicalDrill',
  NonDrugMedicalSuppliesMedicalEquipmentDermatome = 'NonDrugMedicalSuppliesMedicalEquipmentDermatome',
  NonDrugMedicalSuppliesMedicalEquipmentLegTractionSplint = 'NonDrugMedicalSuppliesMedicalEquipmentLegTractionSplint',
  NonDrugMedicalSuppliesMedicalEquipmentOther = 'NonDrugMedicalSuppliesMedicalEquipmentOther',
  NonDrugMedicalSuppliesConsumablesMedicalTourniquets = 'NonDrugMedicalSuppliesConsumablesMedicalTourniquets',
  NonDrugMedicalSuppliesConsumablesFirstAidKits = 'NonDrugMedicalSuppliesConsumablesFirstAidKits',
  NonDrugMedicalSuppliesConsumablesViralBacteriaFilter = 'NonDrugMedicalSuppliesConsumablesViralBacteriaFilter',
  NonDrugMedicalSuppliesConsumablesCentralVenousCatheters = 'NonDrugMedicalSuppliesConsumablesCentralVenousCatheters',
  NonDrugMedicalSuppliesConsumablesSetIntraosseousInfusionKit = 'NonDrugMedicalSuppliesConsumablesSetIntraosseousInfusionKit',
  NonDrugMedicalSuppliesConsumablesSetInfusionAdult = 'NonDrugMedicalSuppliesConsumablesSetInfusionAdult',
  NonDrugMedicalSuppliesConsumablesSetInfusionPaediatric = 'NonDrugMedicalSuppliesConsumablesSetInfusionPaediatric',
  NonDrugMedicalSuppliesConsumablesDrainThoracicInsertionSet = 'NonDrugMedicalSuppliesConsumablesDrainThoracicInsertionSet',
  NonDrugMedicalSuppliesConsumablesInsulinSyringes = 'NonDrugMedicalSuppliesConsumablesInsulinSyringes',
  NonDrugMedicalSuppliesConsumablesSyringePensDiabetics = 'NonDrugMedicalSuppliesConsumablesSyringePensDiabetics',
  NonDrugMedicalSuppliesConsumablesGlucometers = 'NonDrugMedicalSuppliesConsumablesGlucometers',
  NonDrugMedicalSuppliesConsumablesXRayCartridges = 'NonDrugMedicalSuppliesConsumablesXRayCartridges',
  NonDrugMedicalSuppliesConsumablesOther = 'NonDrugMedicalSuppliesConsumablesOther',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsBasicSurgery = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsBasicSurgery',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDressing = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDressing',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsCraniotomy = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsCraniotomy',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsLaparotomyAndCaesarean = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsLaparotomyAndCaesarean',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDPCSuture = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDPCSuture',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDebridement = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDebridement',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsSkinGraft = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsSkinGraft',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsFinePaediatrics = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsFinePaediatrics',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsThoracotomyComplementary = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsThoracotomyComplementary',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsAmputation = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsAmputation',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBasicBoneSurgery = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBasicBoneSurgery',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBasicBoneSurgeryCurettes = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBasicBoneSurgeryCurettes',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBoneWiringAndKirshner = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBoneWiringAndKirshner',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsPlasterCastsRemoval = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsPlasterCastsRemoval',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsTractionPlusTenBows = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsTractionPlusTenBows',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetExternalFixationLargeFixatorsAndInstruments = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetExternalFixationLargeFixatorsAndInstruments',
  NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsOther = 'NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsOther',
}

export const allPpeTypes = Object.keys(PpeTypeEnum);

export const getDisplayNameMessageID: (ppeType: PpeTypeEnum) => MessageID = (
  ppeType: PpeTypeEnum
) => {
  return `resourceType_${ppeType}_displayName` as MessageID;
};

// NOTE: Order matters, it represents the ordinal value
export const PPE_TYPES = [
  PpeTypeEnum.TypeIIRSurgicalMasks,
  PpeTypeEnum.FFP1RespiratorMasks,
  PpeTypeEnum.FFP2RespiratorMasks,
  PpeTypeEnum.FFP3RespiratorMasks,
  PpeTypeEnum.Gowns,
  PpeTypeEnum.Aprons,
  PpeTypeEnum.Gloves,
  PpeTypeEnum.Scrubs,
  PpeTypeEnum.SafetyGlasses,
  PpeTypeEnum.FaceVisors,
  PpeTypeEnum.AlcoholHandGel,
  PpeTypeEnum.Other,
  PpeTypeEnum.DomesticSanitarySanitaryTowels,
  PpeTypeEnum.DomesticSanitaryNappiesSize0,
  PpeTypeEnum.DomesticSanitaryNappiesSize1,
  PpeTypeEnum.DomesticSanitaryNappiesSize2,
  PpeTypeEnum.DomesticSanitaryNappiesSize3,
  PpeTypeEnum.DomesticSanitaryNappiesSize4,
  PpeTypeEnum.DomesticSanitaryNappiesSize5,
  PpeTypeEnum.DomesticSanitaryNappiesSize6,
  PpeTypeEnum.DomesticSanitaryBreastPads,
  PpeTypeEnum.DomesticSanitaryHairbrushes,
  PpeTypeEnum.DomesticSanitaryLiquidSoap,
  PpeTypeEnum.DomesticSanitaryWetWipes,
  PpeTypeEnum.DomesticSanitaryToothbrushes,
  PpeTypeEnum.DomesticSanitaryToothpaste,
  PpeTypeEnum.DomesticSanitaryTowels,
  PpeTypeEnum.DomesticSanitaryToiletPaper,
  PpeTypeEnum.DomesticSanitaryPocketTissues,
  PpeTypeEnum.DomesticSanitaryShavingGelRazors,
  PpeTypeEnum.DomesticSanitaryOther,
  PpeTypeEnum.DomesticNonPerishableFoodDrinkProteinBars,
  PpeTypeEnum.DomesticNonPerishableFoodDrinkCannedFood,
  PpeTypeEnum.DomesticNonPerishableFoodDrinkDryFood,
  PpeTypeEnum.DomesticNonPerishableFoodDrinkInstantFood,
  PpeTypeEnum.DomesticNonPerishableFoodDrinkBabyFood,
  PpeTypeEnum.DomesticNonPerishableFoodDrinkEnergyDrinks,
  PpeTypeEnum.DomesticNonPerishableOther,
  PpeTypeEnum.DomesticOtherFoilSurvivalBlankets,
  PpeTypeEnum.DomesticOtherThermalClothingNew,
  PpeTypeEnum.DomesticOtherSleepingBags,
  PpeTypeEnum.DomesticOtherBedHospital,
  PpeTypeEnum.DomesticOtherLargeOrMediumBackpacks,
  PpeTypeEnum.DomesticOtherPowerBanksAndChargingCables,
  PpeTypeEnum.DomesticOtherTorches,
  PpeTypeEnum.DomesticOtherElectricityGenerators,
  PpeTypeEnum.DomesticOtherBootDriers,
  PpeTypeEnum.DomesticOtherHotWaterBottles,
  PpeTypeEnum.DomesticOtherInsulatedFlasks,
  PpeTypeEnum.DomesticOtherDisposableTableware,
  PpeTypeEnum.DomesticOtherCookingStoves,
  PpeTypeEnum.DomesticOtherBinBags,
  PpeTypeEnum.DomesticOtherOther,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentPatientMonitor,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentAnaesthesiaMachine,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentECGRecorder,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentDefibrillator,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentSyringePump,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentInfusionPump,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentExaminationLightLed,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentFootOperatedSuctionPump,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentPatientVentilator,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentMobileUltrasoundScanner,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentSelfInflatingBagSet,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentCapnometer,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentXRayUnit,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentSurgicalDrill,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentDermatome,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentLegTractionSplint,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentOther,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesMedicalTourniquets,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesFirstAidKits,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesViralBacteriaFilter,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesCentralVenousCatheters,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesSetIntraosseousInfusionKit,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesSetInfusionAdult,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesSetInfusionPaediatric,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesDrainThoracicInsertionSet,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesInsulinSyringes,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesSyringePensDiabetics,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesGlucometers,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesXRayCartridges,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesOther,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsBasicSurgery,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDressing,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsCraniotomy,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsLaparotomyAndCaesarean,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDPCSuture,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsDebridement,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsSkinGraft,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsFinePaediatrics,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetGeneralSurgeryInstrumentsThoracotomyComplementary,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsAmputation,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBasicBoneSurgery,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBasicBoneSurgeryCurettes,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsBoneWiringAndKirshner,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsPlasterCastsRemoval,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetOrthoSurgeryInstrumentsTractionPlusTenBows,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsSetExternalFixationLargeFixatorsAndInstruments,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsOther,
];

// Starting from 1
export const PPE_TYPES_ORDINAL_MAP: Record<PpeTypeEnum, number> = Object.assign(
  {},
  ...PPE_TYPES.map((x, idx) => ({ [x]: idx + 1 }))
);

const RESOURCE_TYPES_OTHER = [
  PpeTypeEnum.Other,
  PpeTypeEnum.DomesticSanitaryOther,
  PpeTypeEnum.DomesticOtherOther,
  PpeTypeEnum.DomesticNonPerishableOther,
  PpeTypeEnum.NonDrugMedicalSuppliesConsumablesOther,
  PpeTypeEnum.NonDrugMedicalSuppliesMedicalEquipmentOther,
  PpeTypeEnum.NonDrugMedicalSuppliesSurgicalInstrumentsAndFixatorsOther,
];

export function getPpeTypeEnumFromInt(value: number): PpeTypeEnum | undefined {
  // Starting from 1
  return PPE_TYPES[value - 1];
}

export function isResourceTypeOther(type: PpeTypeEnum): boolean {
  return RESOURCE_TYPES_OTHER.some((typeOther) => typeOther === type);
}

export const PpeTypeName = {
  [PpeTypeEnum.TypeIIRSurgicalMasks]: 'Type IIR Surgical Masks',
  [PpeTypeEnum.FFP1RespiratorMasks]: 'FFP1 Respirator Masks',
  [PpeTypeEnum.FFP2RespiratorMasks]: 'FFP2 Respirator Masks',
  [PpeTypeEnum.FFP3RespiratorMasks]: 'FFP3 Respirator Masks',
  [PpeTypeEnum.Gowns]: 'Gowns',
  [PpeTypeEnum.Aprons]: 'Aprons',
  [PpeTypeEnum.Gloves]: 'Gloves',
  [PpeTypeEnum.Scrubs]: 'Scrubs',
  [PpeTypeEnum.SafetyGlasses]: 'Safety Glasses',
  [PpeTypeEnum.FaceVisors]: 'Face Visors',
  [PpeTypeEnum.AlcoholHandGel]: 'Alcohol Hand Gel',
  [PpeTypeEnum.Other]: 'Other',
};

export interface PpeType {
  ppeType: number;
  ppeTypeOther: Nullable<string>;
}

export interface NeedPpeType extends PpeType {
  status: number; // TODO: Conversion from int to enum
}
